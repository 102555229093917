import * as React from "react"
import { Link } from "gatsby"

import Showcase from "../components/features/showcase"
import SimpleNav from "../components/navs/simpleNav"
import Products from "../components/features/products"
import FourColFooter from "../components/footers/fourColFooter"
import LTextDivider from "../components/dividers/lTextDivider"
import SocialOnlyFooter from "../components/footers/socialOnlyFooter"

import { Blue } from "../data/color"
import websites from "../data/websites"

const HolidaySite = () => (
  <div>
  
  <SimpleNav color={Blue.color} textColor={Blue.textBold} focusColor={Blue.focusColor} />
  <Showcase bgBold={Blue.bgBold} hoverBold={Blue.hoverBold} name={'WGBH 2020 Digital Holiday Card'} desc={'Developed with CreateJS for the animation events and CSS grid for the layout. Animation and layout design credit goes to the WGBH Digital Creative team.'} visit="https://wgbhdigital.org/greeting/2019/" picture="https://i.imgur.com/Rx1uQ7d.png" />

  <Products name={"More"} projects={websites} textBold={Blue.textBold} textMd={Blue.textMd} />
  <SocialOnlyFooter />

</div>

)

export default HolidaySite